import Vue from "vue";

/*TITLE*/
document.title = "Abstract Aguadulce | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Abstract Aguadulce";
Vue.prototype.$subtitle = "¿Y si a partir de ahora viviéramos así siempre?";

/*INTRO*/
Vue.prototype.$promoter = "Abstract Aguadulce";
Vue.prototype.$introSubtitle = "¿Y si a partir de ahora viviéramos así siempre?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "michigan-clear-salon--20230111040143.jpg";
Vue.prototype.$image_kitchen = "";
Vue.prototype.$image_bath1 = "master-bath-vista-lavabo-bianco-carrara-michigan-clear--20230111040107.jpg";
Vue.prototype.$image_bath2 = "banyo-auxiliar-capri-michigan-clear--20230111040136.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "michigan-clear-salon--20230111040143.jpg",
  },
  {
    src: "salon-abstract-aguadulce-matika-bone-100x100--20230111040152.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "master-bath-vista-lavabo-bianco-carrara-michigan-clear--20230111040107.jpg",
  },
  {
    src: "master-bath-vista-inodoro-bianco-carrara-michigan-clear--20230111040115.jpg",
  },
  {
    src: "master-bath-vista-lavabos-carrara-bottega-caliza-copia--20230111040122.jpg",
  },
  {
    src: "master-bath-vista-inodoro-bianco-carrara-bottega-caliza--20230111040128.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-auxiliar-capri-michigan-clear--20230111040136.jpg",
  },
  {
    src: "banyo-auxiliar-capri-bottega-caliza-copia--20230111040141.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "6";
Vue.prototype.$gallery_grid_room = "6";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=camino%20de%20los%20parrales%20nº%20115%20•%2004720%20aguadulce%20•%20almería",
    text: "Camino De los Parrales nº 115 • 04720 Aguadulce • Almería",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=camino%20de%20los%20parrales%20nº%20115%20•%2004720%20aguadulce%20•%20almería",
    text: "Camino De los Parrales nº 115 • 04720 Aguadulce • Almería",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:646516704",
    text: "646516704",
  },
  {
    icon: "mdi-email",
    link: "mailto:espacio-abstract@ie-sa.es",
    text: "espacio-abstract@ie-sa.es",
  },
];
